<template>
  <div>
    <div class="confirm_trip" v-if="showConfirm">
      <img src="../assets/images/dwellys-logo.png" alt="logo" width="135px" class="dlogo" />

      <h4>
        You have been confirmed to begin Trip with a Dwellys Collaborator.
      </h4>
      <h6>Collaborator Details</h6>
      <div class="d-flex details" v-loading="loading">
        <img :src="collaborator.image" alt="collaborator image" width="135px" />
        <div>
          <span class="d-block">{{ collaborator.name }}</span>
          <span class="d-block">{{ collaborator.email }}</span>
          <span class="d-block">{{ collaborator.contact }}</span>
        </div>
      </div>
      <div class="confirm-button">
        <!-- <el-button
          type="success"
          icon="el-icon-coin"
          size="small"
          :disabled="payDisabled"
          @click="showPaymentModal = true"
          >Make Payments</el-button
        > -->
        <el-button
          class="full-width"
          type="primary"
          icon="el-icon-mouse"
          size="small"
          :loading="tripBtnLoading"
          @click="startTrip"
          >Start Trip</el-button
        >
      </div>
      <!-- <el-dialog :visible.sync="showPaymentModal" width="25%" title="Trip Payments">
        <div class="payments_window">
          <div class="dwellys_info">
            <h3>Payments should be made to details below:</h3>
            <address>
              Name: <b>Dwellys Limited</b> <br />
              Number: <b style="font-size:18px;">0540571522</b> <br />
              Amount: <b>GH₵100</b>
            </address>
          </div>
          <br />
          <div class="dwellys_info">
            <h3>Information on Client</h3>
            <address>
              Name: <b>{{ client.name }}</b> <br />
              Number: <b>{{ client.contact }}</b> <br />
            </address>
            <br />
            <el-radio v-model="selectedNetwork" label="1">Mtn MoMo</el-radio>
            <el-radio v-model="selectedNetwork" label="2">AirtelTigo Cash</el-radio>
            <el-radio v-model="selectedNetwork" label="3">Vodafone Cash</el-radio>
          </div>
        </div>
        <div class="mt-1">
          <el-button
            class="full-width"
            :loading="payBtnLoading"
            type="success"
            icon="el-icon-coin"
            @click="confirmPayments"
            >Confirm Payments</el-button
          >
        </div>
      </el-dialog> -->
    </div>

    <div class="confirm_trip" v-else>
      <img src="../assets/images/dwellys-logo.png" alt="logo" width="135px" class="dlogo" />
      <br />
      <el-timeline>
        <el-timeline-item timestamp="2018/4/2" placement="top" color="primary">
          <el-card>
            <div class="d-flex">
              <img :src="collaborator.image" alt="img" width="50px" />
              <div class="ml-1">
                <span class="d-block">{{ collaborator.name }}</span>
                <span class="d-block">{{ collaborator.contact }}</span>
              </div>
            </div>
          </el-card>
        </el-timeline-item>
        <el-timeline-item placement="top">
          <el-card>
            <h3>{{ client.name }}</h3>
            <p>{{ client.contact }}</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item placement="top">
          <el-card>
            <img src="../assets/images/loading.gif" alt="" width="100%" />
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import tripsApi from "@/api/trips";
import usersApi from "@/api/users";

export default {
  name: "confirmTrip",
  data() {
    return {
      selectedNetwork: "",
      networks: ["Mtn", "AirtelTigo", "Vodafone"],
      loading: true,
      tripBtnLoading: false,
      payBtnLoading: false,
      tripDisabled: true,
      payDisabled: false,
      showConfirm: true,
      collaborator: {
        name: "",
        conatact: "",
        email: "",
        id: "",
        image: ""
      },
      client: {
        name: "",
        contact: "",
        email: "",
        id: ""
      },
      tripInfo: {
        trip_uuid: "",
        confirm: "yes",
        confirmer: "client",
        confirmer_uuid: "",
        client_rep_logitude_location: "",
        client_rep_latitude_location: ""
      }
    };
  },
  mounted() {
    this.tripInfo.trip_uuid = this.$route.query.trip_uuid;
    this.tripInfo.confirmer_uuid = this.$route.query.confirmer_uuid;
    this.getTrips();
    this.getLocation();
  },
  methods: {
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        this.errorMessage("Geolocation is not supported by this browser.");
      }
    },
    showPosition(position) {
      this.tripInfo.client_rep_logitude_location = position.coords.longitude;
      this.tripInfo.client_rep_latitude_location = position.coords.latitude;
    },
    getTrips() {
      let self = this;
      tripsApi
        .getClientTrip(this.$route.query.trip_uuid)
        .then(response => {
          let trip = response.data;
          self.collaborator.name = `${trip.user.first_name} ${trip.user.last_name}`;
          self.collaborator.contact = trip.user.contact;
          self.collaborator.email = trip.user.email;
          self.collaborator.id = trip.user.uuid;
          self.client.name = `${trip.client.first_name} ${trip.client.last_name}`;
          self.client.contact = trip.client.contact;
          self.client.email = trip.client.email;
          self.client.id = trip.client.uuid;
          self.getCollaboratorImage();
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    getCollaboratorImage() {
      usersApi
        .getCollaboratorFile(this.collaborator.id)
        .then(response => {
          if (response.message == "error") {
            this.infoMessage("Collaborator has no Image yet");
            this.loading = false;
          } else {
            this.collaborator.image =
              "https://staging.dwellysops.app/storage/" + response.data.photo;
            this.loading = false;
          }
        })
        .catch(error => this.errorMessage(error.message));
    },
    startTrip() {
      this.tripBtnLoading = true;
      tripsApi
        .clientConfirmTrip(this.tripInfo)
        .then(response => {
          console.log(response);
          this.successNotification(
            "Thank You",
            "Your trip has been started, find below trip information."
          );
          this.showConfirm = false;
          this.tripBtnLoading = false;
        })
        .catch(error => {
          this.tripBtnLoading = false;
          this.errorMessage(error.message);
        });
    },
    confirmPayments() {
      this.tripDisabled = false;
      this.payDisabled = true;
      this.showPaymentModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm_trip {
  position: relative;
  border: 1px solid rgb(161, 160, 160);
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  margin: 20px auto;

  .dlogo {
    position: absolute;
    top: 0;
    font-size: 18px;
    line-height: 1;
    margin: -9px 0 0; /* half of font-size */
    background: #fff;
    padding: 0 10px;
  }

  h4 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .details {
    margin-top: 10px;
    img {
      margin-right: 10px;
    }
    span {
      font-size: 16px;
      font-weight: 500;
      margin: 5px 0px;
    }
  }
  .confirm-button {
    margin-top: 20px;
  }
}

.payments_window {
  .dwellys_info {
    border: 1px solid #e1e1f7;
    background: #efeff8;
    border-radius: 5px;
    padding: 10px;
  }

  .clients_info {
    margin-left: 5px;
  }
}
</style>
